import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Avatar from "react-avatar";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import CollectionItemCard from "./CollectionItemCard";
import imgLogo from "../../assets/images/favicon.png";
import "./Home.scss";

const Home = props => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [enablePersonalization, setEnablePersonalization] = React.useState(
    true
  );
  const [collection, setCollection] = React.useState({
    collection: { items: [] }
  });
  const [sidQuery, setSidQuery] = React.useState(null);
  const [ctaData, setCtaData] = React.useState(null);
  const [availableItemsCount, setAvailableItemsCount] = React.useState(
    Math.floor((window.innerWidth - 200) / 210)
  );
  const [userId, setUserId] = React.useState("");
  const valRef = React.useRef(0);

  const history = useHistory();

  const initConfigurations = async () => {
    const metaTags = document.getElementsByTagName("meta");
    let metaUserId = null;
    let metaCollectionId = null;
    let metaTenantId = null;

    for (let i = 0; i < metaTags.length; i += 1) {
      if (metaTags[i].name === "userId") {
        metaUserId = metaTags[i].content;
      }

      if (metaTags[i].name === "collectionId") {
        metaCollectionId = metaTags[i].content;
      }

      if (metaTags[i].name === "tenantId") {
        metaTenantId = metaTags[i].content;
      }

      // if (metaTags[i].name === 'ctaId') {
      //   ctaId = metaTags[i].content;
      // }
    }

    let searchText = history.location.search;
    searchText = searchText.replace("?", "");
    const searchParams = searchText.split("&");
    let newSidQuery = null;
    const searchValues = {};

    if (searchParams.length > 0) {
      searchParams.forEach(sp => {
        const valuePair = sp.split("=");
        searchValues[valuePair[0]] = valuePair[1];
      });
    }

    if (searchValues.sid) {
      newSidQuery = searchValues.sid;
    }

    try {
      if (
        !metaUserId ||
        !metaCollectionId ||
        metaUserId === "" ||
        metaCollectionId === ""
      ) {
        gotoNotFound();
        return;
      }

      setUserId(metaUserId);
      const collectionAPIUrl = `${process.env.REACT_APP_API_SERVER_BASE_URL}/collections/v1/items?ownedBy=${metaUserId}&collectionId=${metaCollectionId}`;
      const configOptions = {
        headers: {
          "Content-Type": "application/json"
        },
        crossdomain: true
      };

      const response = await axios.get(collectionAPIUrl, configOptions);

      if (response.data.data.deletedAt) {
        gotoRemovedSite();
        return;
      }

      process.env.OG_TITLE = response.data.data.title;
      // turn off avatar and contact info if flagged off for this site
      const {
        enablePersonalization: newEnablePersonalization
      } = response.data.data;
      if (newEnablePersonalization === false) {
        setEnablePersonalization(false);
      }

      setCollection(response.data.data);
      setIsLoading(false);
      setSidQuery(newSidQuery);

      if (newSidQuery) {
        try {
          const shareAPIUrl = `${process.env.REACT_APP_API_SERVER_BASE_URL}/users/v1/shares/${newSidQuery}?tenantId=${metaTenantId}`;
          const shareResponse = await axios.get(shareAPIUrl, configOptions);
          const shareInfo = shareResponse.data.data;

          if (shareInfo.ctaId) {
            const ctaResponse = await axios.get(
              `${process.env.REACT_APP_API_SERVER_BASE_URL}/ctas/v1/ctas/${shareInfo.ctaId}/public?tenantId=${metaTenantId}`
            );

            setCtaData({
              buttonStyle: ctaResponse.data.data.options.style,
              message: ctaResponse.data.data.message,
              buttonText: ctaResponse.data.data.buttonText,
              targetUrl: ctaResponse.data.data.targetUrl,
              userName: ctaResponse.data.data.userName,
              imageUrl: ctaResponse.data.data.imageUrl
            });
          }
        } catch (errr) {
          console.log("Fetch share info error: ");
          console.log(errr);
        }
      }
    } catch (error) {
      console.log("error: ", error.message);
      gotoNotFound();
    }
  };

  React.useEffect(() => {
    initConfigurations();

    window.addEventListener("resize", changeContianerWidth);
  }, []);

  const changeContianerWidth = () => {
    let newAvailableItemsCount = Math.floor((window.innerWidth - 200) / 210);

    if (valRef.current < newAvailableItemsCount) {
      newAvailableItemsCount = valRef.current;
    }

    setAvailableItemsCount(newAvailableItemsCount);
  };

  React.useEffect(() => {
    if (!isLoading) {
      valRef.current = collection.items.length;
      changeContianerWidth();
    }
  }, [collection.items, isLoading]);

  const gotoNotFound = () => {
    window.location.assign(
      `${process.env.REACT_APP_API_CLIENT_BASE_URL}/not-found`
    );
  };

  const gotoRemovedSite = () => {
    window.location.assign(
      `${process.env.REACT_APP_API_CLIENT_BASE_URL}/removed-site`
    );
  };

  let user = {
    avatar: {}
  };

  if (collection.user) {
    user = collection.user;
  }

  let logoImageUrl = null;
  let color = "#47c1cc";
  if (collection.branding) {
    color = collection.branding.color;

    if (collection.branding.logo) {
      logoImageUrl = collection.branding.logo;
    }
  }

  // flags
  const enableDescription =
    collection.description && collection.description.trim().length > 0;
  const enableTitleBox = enablePersonalization || enableDescription; // turn off the title box if both are off

  // if (ctaData) {
  //   console.log(ctaData.imageUrl);
  // }

  return (
    <div className={ctaData ? "home-container cta-attach" : "home-container"}>
      <Helmet>
        <title>{collection.title || ""}</title>
        {/* <meta
          name="description"
          content={collection.description}
        />
        <meta
          property="og:title"
          content={collection.title || ""}
        />
        <meta
          property="og:description"
          content={collection.description}
        />
        <meta property="og:image" content={logoImageUrl} />
        <meta property="siteId" content={collection.id} /> */}
      </Helmet>
      {isLoading && (
        <div className="loading-wrapper">
          {/* <img className="cc-logo" src={imgLogo} alt="logo" /> */}
          <BeatLoader size={20} margin={4} color="#36D7B7" />
        </div>
      )}
      {!isLoading && (
        <div className="brand-box">
          <div
            className="custom-background"
            style={{ backgroundColor: color }}
          />
          <div className="triangle1" style={{ backgroundColor: color }} />
          <div className="triangle2" />
          <div className="triangle3" />
        </div>
      )}
      {!isLoading && (
        <div className="site-content-wrapper">
          {logoImageUrl && (
            <div className="company-logo">
              <img src={logoImageUrl} alt="logo" />
            </div>
          )}
          <div className="title-section">
            {enablePersonalization && (
              <div className="avatar-box">
                <Avatar
                  name={user.name || ""}
                  size={100}
                  round={true}
                  color="#143045"
                  fgColor="white"
                  className="mr-2"
                  src={user.avatar.thumbnail}
                />
              </div>
            )}
            {enableTitleBox && (
              <div className="title-box">
                {/* <div className="site-title">
                {collection.title || ""}
              </div> */}
                <div className="site-description">{collection.description}</div>
                {enablePersonalization && (
                  <a href={`mailto:${user.email}`} target="_top">
                    Contact {user.name}
                  </a>
                )}
                {/* <div>
              <img
                src={getSimpleIconByName("link")}
                alt="share site"
              />
              <a href={collection.urlPath} target="_top">
                SHARE
              </a>
              </div> */}
              </div>
            )}
          </div>
          <div
            className="collections-container"
            style={{
              width: `${Math.max(availableItemsCount * 210 + 10, 620)}px`,
              justifyContent: availableItemsCount > 2 ? "flex-start" : "center"
            }}
          >
            {collection.items.map((it, idx) => (
              <CollectionItemCard
                data={it}
                key={`card_${idx}`}
                sidQuery={sidQuery}
                siteId={collection.id}
                ownerBy={userId}
                collectionId={collection.collectionId}
              />
            ))}
          </div>
        </div>
      )}
      {ctaData && (
        <div
          className={`cta-view ${
            ctaData.buttonStyle === "chatBubble" ? "" : " full-width-style"
          }`}
        >
          <div className="profile-image">
            <img src={ctaData.imageUrl} alt="avatar3" />
          </div>
          {ctaData.buttonStyle === "chatBubble" && (
            <div className="arrow-left"></div>
          )}
          <div className="content-view">
            <div className="user-name">{ctaData.userName}</div>
            <div className="action-view">
              <div className="message-content">
                {ctaData.message === ""
                  ? "Enter your message here"
                  : ctaData.message}
              </div>
              <a
                href={ctaData.targetUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="action-button"
              >
                {ctaData.buttonText}
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Home.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object
};

export default Home;
