import React from "react";
import PropTypes from "prop-types";
import "./CollectionItemCard.scss";

const aliasTypeGenerator = (type) => {
  let aliasAction = "View";
  let aliasType = "Now";

  switch (type) {
    case "pdf":
      aliasType = "PDF";
      break;
    case "ppt":
      aliasType = "Presentation";
      break;
    case "mp4":
    case "avi":
    case "mpeg":
      aliasType = "Video";
      break;
    case "mp3":
    case "m4a":
    case "flac":
    case "wav":
    case "wma":
    case "aac":
      aliasAction = "Listen";
      aliasType = "Now";
      break;
    case "jpg":
    case "jpeg":
    case "png":
    case "image":
      aliasType = "Image";
      break;
    case "html":
      aliasType = "Page";
      break;
    default:
      aliasType = "Now";
      break;
  }

  return `${aliasAction} ${aliasType}`;
};

// thumbnailUrl,
// title: item.meta.contentTitle || props.data.name,
// description: item.meta.contentDescription || '',
// ext: item.original.ext,
// type: item.type,
// shortUrl: item.urls.short

const CollectionItemCard = (props) => {
  const [expandDesc, setExpandDesc] = React.useState(false);

  const renderThumbnailImage = (item) => {
    if (item.type === "pdf") {
      return (
        <div
          className="_df_thumb"
          id="df_manual_book"
          tags="3d,images"
          source={item.fullUrl}
          thumb={item.thumbnailUrl}
          onClick={e => e.stopPropagation()}
        >
          <div
            className="_df_book-cover"
          >
            <img className="_df_thumbnail_image" src={item.thumbnailUrl} alt="pdf thumbnail" />
            <span className="_df_book-title">
              {props.data.title}
            </span>
          </div>
        </div>
      );
    } else {
      if (item.thumbnailUrl) {
        return (
          <img
            className="thumbnail-image"
            src={item.thumbnailUrl}
            alt="no thumbnail"
          />
        );
      } else {
        return (
          <div className="thumbnail-image no-thumbnail">
            {(item.ext || "").toUpperCase()}
          </div>
        );
      }
    }
  };

  const onCardClick = () => {
    const targetUrl = buildCardUrl();

    window.open(targetUrl.toString(), "_blank");
  };

  const getShortUrl = () => {
    if (props.sidQuery) {
      return `${props.data.shortUrl}?sid=${props.sidQuery}&siteId=${props.siteId}`;
    } else {
      return props.data.shortUrl;
      // if (props.data.type !== 'pdf') {
      //   return props.data.shortUrl;
      // }

      // return `${window.location.origin}/pdf-show?url=${props.data.shortUrl}`;
    }
  }

  const buildCardUrl = (url) => {
    const targetUrl = new URL(props.data.shortUrl);
    if (props.sidQuery) {
      targetUrl.searchParams.set("sid", props.sidQuery);
    }
    if (props.siteId) {
      targetUrl.searchParams.set("siteId", props.siteId);
    }
    return targetUrl.toString();
  };

  const onClickMore = e => {
    e.stopPropagation();
    setExpandDesc(!expandDesc);
  };

  return (
    <div className="collection-card" onClick={onCardClick}>
      <div className="thumbnail-section">
        {/* <img
          className="icon-box"
          src={getSimpleIconByName(props.data.ext || "file")}
          alt="file type"
        /> */}
        {renderThumbnailImage(props.data)}
      </div>
      <div className="description">
        <div className="collection-item-title">{props.data.title}</div>
        {expandDesc &&
          <div className="details">
            {props.data.description}
          </div>
        }
        {
          props.data.description !== '' && (
            <div className="more-container">
              <div className="more-button" onClick={onClickMore}>…</div>
            </div>
          )
        }
      </div>
      <div className="navigator">
        <a
          className="nav-items"
          target="_blank"
          href={buildCardUrl()}
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {`${aliasTypeGenerator(props.data.type)}`}
        </a>
      </div>
    </div>
  );
}

CollectionItemCard.propTypes = {
  data: PropTypes.object
};

export default CollectionItemCard;
