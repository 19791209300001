import React from 'react';
import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";
import './PDFViewer.scss';

const PDFViewer = props => {
  let color = "#47c1cc";
  const [sidQuery, setSidQuery] = React.useState(null);
  const [brandingOption, setBrandingOption] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  /* ----------- Processing URL parameters ------------ */
  let searchText = props.history.location.search;
  searchText = searchText.replace('?', '');
  const searchParams = searchText.split('&');
  let newSidQuery = null;
  const searchValues = {};

  const gotoNotFound = () => {
    window.location.assign(
      `${process.env.REACT_APP_API_CLIENT_BASE_URL}/not-found`
    );
  };

  const gotoRemovedSite = () => {
    window.location.assign(
      `${process.env.REACT_APP_API_CLIENT_BASE_URL}/removed-site`
    );
  };

  if (searchParams.length > 0) {
    searchParams.forEach(sp => {
      const valuePair = sp.split('=');
      searchValues[valuePair[0]] = valuePair[1];
    });
  }

  if (searchValues['sid']) {
    newSidQuery = searchValues['sid'];
  }

  if (!searchValues['params']) {
    gotoNotFound();
  }

  const decodedParams = decodeURIComponent(searchValues['params']);
  let newParams = {};
  let userId = null;
  // let collectionId = null;

  try {
    newParams = JSON.parse(decodedParams);

    // if (searchValues['collectionId']) {
    //   collectionId = searchValues['collectionId'];
    // }

    // if (searchValues['userId']) {
    //   userId = searchValues['userId'];
    // }

    // if (newParams['userId']) {
    //   userId = newParams['userId'];
    // }

    // if (newParams['collectionId']) {
    //   collectionId = newParams['collectionId'];
    // }
  } catch (err) {
    console.log("error: ", err.message);
    gotoNotFound();
  }
  // setPdfUrl(newParams.url);
  /* ------------------------------------------------- */

  const initSettings = async () => {
    setIsLoading(true);
    try {
      setSidQuery(newSidQuery);

      if (!newParams['tenantId']) {
        setIsLoading(false);
        return;
      }

      const collectionAPIUrl = `${process.env.REACT_APP_API_SERVER_BASE_URL}/collections/v1/items?tenantId=${newParams['tenantId']}&branding=true`;
      const configOptions = {
        headers: {
          "Content-Type": "application/json"
        },
        crossdomain: true
      };

      const response = await axios.get(collectionAPIUrl, configOptions);
      console.log({ response , info: "PDFViewer.initSettings.response" });
      if (response.data.data.deletedAt) {
        gotoRemovedSite();
      }

      setBrandingOption(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log("error: ", error.message);
      setIsLoading(false);
      // gotoNotFound();
    }
  }

  React.useEffect(() => {
    initSettings();
  }, []);

  if (brandingOption) {
    color = brandingOption.color;
  }

  return (
    <div className="pdf-viewer-container">
      {
        isLoading && (
          <div className="loading-wrapper">
            {/* <img className="cc-logo" src={imgLogo} alt="logo" /> */}
            <BeatLoader size={20} margin={4} color="#36D7B7" />
          </div>
        )
      }
      {!isLoading && (
        <div className="brand-box">
          <div
            className="custom-background"
            style={{ backgroundColor: color }}
          />
          <div className="triangle1" style={{ backgroundColor: color }} />
          <div className="triangle2" />
          <div className="triangle3" />
        </div>
      )}
      <div
        className="_df_book"
        height="100%"
        webgl="true"
        backgroundcolor="transparent"
        source={newParams['url']}
        id="df_manual_book"
      />
    </div>
  )
}

export default PDFViewer;
