import React from 'react';
import axios from "axios";
import './PDFViewer.scss';

const PDFViewer = props => {
  let color = "#47c1cc";
  const [enablePersonalization, setEnablePersonalization] = React.useState(true);
  const [sidQuery, setSidQuery] = React.useState(null);
  const [collection, setCollection] = React.useState({
    items: []
  });

  const initSettings = async () => {
    const { userId, collectionId } = props.match.params;

    let searchText = props.history.location.search;
    searchText = searchText.replace('?', '');
    const searchParams = searchText.split('&');
    let newSidQuery = null;

    let searchValues = null;
    if (searchParams.length > 0) {
      searchValues = searchParams[0].split('=');
    }

    if (searchValues[0] === 'sid') {
      newSidQuery = searchParams[0];
    }

    if (!userId || !collectionId) {
      gotoNotFound();
    }

    const collectionAPIUrl = `${process.env.REACT_APP_API_SERVER_BASE_URL}/collections/v1/items?ownedBy=${userId}&collectionId=${collectionId}`;
    const configOptions = {
      headers: {
        "Content-Type": "application/json"
      },
      crossdomain: true
    };

    try {
      const response = await axios.get(collectionAPIUrl, configOptions);
      console.log({ response , info: "PDFViewer.initSettings.response" });
      if (response.data.data.deletedAt) {
        gotoRemovedSite();
      }

      process.env.OG_TITLE = response.data.data.title;
      // turn off avatar and contact info if flagged off for this site
      const { enablePersonalization } = response.data.data;
      if (enablePersonalization === false) {
        setEnablePersonalization(false);
      }

      setCollection(response.data.data);
      setSidQuery(newSidQuery);
    } catch (error) {
      console.log("error: ", error.message);
      gotoNotFound();
    }
  }

  const gotoNotFound = () => {
    window.location.assign(
      `${process.env.REACT_APP_API_CLIENT_BASE_URL}/not-found`
    );
  };

  const gotoRemovedSite = () => {
    window.location.assign(
      `${process.env.REACT_APP_API_CLIENT_BASE_URL}/removed-site`
    );
  };

  React.useEffect(() => {
    initSettings();
  }, []);

  if (collection.branding) {
    color = collection.branding.color;
  }

  return (
    <div className="pdf-viewer-container">
      <div className="brand-box">
        <div
          className="custom-background"
          style={{ backgroundColor: color }}
        />
        <div className="triangle1" style={{ backgroundColor: color }} />
        <div className="triangle2" />
        <div className="triangle3" />
      </div>
      <div
        className="_df_book"
        height="100%"
        webgl="true"
        backgroundcolor="transparent"
        source={window.location.search.replace('?url=', '')}
        id="df_manual_book"
      />
    </div>
  )
}

export default PDFViewer;
