import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";

import thunkMiddleware from "redux-thunk";

import { Route, BrowserRouter as Router } from "react-router-dom";

import rootReducer from "./modules";
import App from "./components/App";
import Home from "./containers/Home";
import PDFViewer from "./components/Sites/PDFViewer";
import NewPDFViewer from "./components/Sites/NewPDFViewer";
import NewHome from "./components/Sites/NewHome";

/**
 * Sentry monitoring for performance and crashes
 * The release variable can't have slashes in it (won't load in sentry console)
 */
 Sentry.init({
  dsn: "https://8a875d49751c49d2a96509f8e15b1ac3@o574936.ingest.sentry.io/5832826",
  integrations: [new Integrations.BrowserTracing()],
  release: `${(process.env.REACT_APP_GIT_SHA || "no-release-set").replace("heads/", "").replace(/\//g, "-")}`,
  environment: process.env.REACT_APP_ENVIRONMENT,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.REACT_APP_SENTRY_TRACE_RATE,
});

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

const renderSite = () => {
  const currentLocation = window.location.href.replace(window.location.search, '');
  const currentSearch = window.location.search;

  const lastCh = currentLocation.slice(
    currentLocation.length - 1,
    currentLocation.length
  );

  if (currentSearch === '') {
    if (lastCh !== "/") {
      window.location.assign(`${currentLocation}/`);
    }
  } else {
    if (lastCh !== "/") {
      window.location.assign(`${currentLocation}/${currentSearch}`);
    }
  }
};

const routes = (
  <Router>
    <App>
      <Route
        exact
        path="/view/pdf-view"
        component={NewPDFViewer}
      />
      <Route
        exact
        path="/sites/:siteName/pdf-view"
        component={PDFViewer}
      />
      <Route exact path="/sites/:userId/:collectionId" render={renderSite} />
      <Route exact path="/sites/:userId/:collectionId/" component={Home} />
      <Route exact path="/sites" render={renderSite} />
      <Route exact path="/sites/:siteName" component={NewHome} />
      <Route
        exact
        path="/:subDomain/sites/:userId/:collectionId/"
        component={Home}
      />
      <Route
        exact
        path="/:subDomain/sites/:siteName"
        component={NewHome}
      />
      {/* <Route
        exact
        path="/:path"
        render={() => {
          window.location.assign(
            `${process.env.REACT_APP_API_CLIENT_BASE_URL}/not-found`
          );
        }}
      /> */}
    </App>
  </Router>
);

ReactDOM.render(
  <Provider store={store}>{routes}</Provider>,
  document.getElementById("root")
);
