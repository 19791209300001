import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Avatar from "react-avatar";
import { Helmet } from "react-helmet";
import CollectionItemCard from "./CollectionItemCard";
import "./Home.scss";

const Home = props => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [enablePersonalization, setEnablePersonalization] = React.useState(true);
  const [collection, setCollection] = React.useState({ items: [] });
  const [error, setError] = React.useState(null);
  const [sidQuery, setSidQuery] = React.useState(null);

  const initData = async () => {
    const { userId, collectionId } = props.match.params;
    let searchText = props.history.location.search;
    searchText = searchText.replace('?', '');
    const searchParams = searchText.split('&');
    let newSidQuery = null;

    let searchValues = null;
    if (searchParams.length > 0) {
      searchValues = searchParams[0].split('=');
    }

    if (searchValues[0] === 'sid') {
      newSidQuery = searchParams[0];
    }

    if (!userId || !collectionId) {
      gotoNotFound();
    }

    const collectionAPIUrl = `${process.env.REACT_APP_API_SERVER_BASE_URL}/collections/v1/items?ownedBy=${userId}&collectionId=${collectionId}`;
    const configOptions = {
      headers: {
        "Content-Type": "application/json"
      },
      crossdomain: true
    };

    try {
      const response = await axios.get(collectionAPIUrl, configOptions);

      if (response.data.data.deletedAt) {
        gotoRemovedSite();
      }

      process.env.OG_TITLE = response.data.data.title;
      // turn off avatar and contact info if flagged off for this site
      const { enablePersonalization: newEnablePersonalization } = response.data.data;
      if (newEnablePersonalization === false) {
        setEnablePersonalization(false);
      }

      setCollection(response.data.data);
      setIsLoading(false);
      setSidQuery(newSidQuery);
    } catch (error) {
      console.log("error: ", error.message);
      gotoNotFound();
    }
  }

  React.useEffect(() => {
    initData();
  });

  const gotoNotFound = () => {
    window.location.assign(
      `${process.env.REACT_APP_API_CLIENT_BASE_URL}/not-found`
    );
  };

  const gotoRemovedSite = () => {
    window.location.assign(
      `${process.env.REACT_APP_API_CLIENT_BASE_URL}/removed-site`
    );
  };

  let user = {
    avatar: {}
  };

  if (collection.user) {
    user = collection.user;
  }

  let logoImageUrl = null;
  let color = "#47c1cc";
  if (collection.branding) {
    color = collection.branding.color;

    if (collection.branding.logo) {
      logoImageUrl = collection.branding.logo;
    }
  }

  // flags
  const enableDescription = (collection.description && collection.description.trim().length > 0);
  const enableTitleBox = (enablePersonalization || enableDescription); // turn off the title box if both are off

  return (
    <div className="home-container new">
      <Helmet>
        <title>{collection.title || ""}</title>
        {/* <meta
          name="description"
          content={collection.description}
        />
        <meta
          property="og:title"
          content={collection.title || ""}
        />
        <meta
          property="og:description"
          content={collection.description}
        />
        <meta property="og:image" content={logoImageUrl} />
        <meta property="siteId" content={collection.id} /> */}
      </Helmet>
      <div className="brand-box">
        <div
          className="custom-background"
          style={{ backgroundColor: color }}
        />
        <div className="triangle1" style={{ backgroundColor: color }} />
        <div className="triangle2" />
        <div className="triangle3" />
      </div>
      {!isLoading &&
        <div className="site-content-wrapper">
          {logoImageUrl && (
            <div className="company-logo">
              <img src={logoImageUrl} alt="logo" />
            </div>
          )}
          <div className="title-section">
            { enablePersonalization && <div className="avatar-box">
              <Avatar
                name={user.name || ""}
                size={100}
                round={true}
                color="#143045"
                fgColor="white"
                className="mr-2"
                src={user.avatar.thumbnail}
              />
            </div>
            }
            { enableTitleBox && <div className="title-box">
              {/* <div className="site-title">
                {collection.title || ""}
              </div> */}
              <div className="site-description">
                {collection.description}
              </div>
              { enablePersonalization && <a href={`mailto:${user.email}`} target="_top">
                Contact {user.name}
              </a>
              }
            </div>
          }
          </div>
          <div className="collections-container">
            {collection.items.map((it, idx) => (
              <CollectionItemCard data={it} key={`card_${idx}`} sidQuery={sidQuery} siteId={collection.id} />
            ))}
          </div>
      
        </div>
      }
    </div>
  );
}

Home.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object
};

export default Home;
